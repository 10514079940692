<template>
    <div>
    <!-- <v-dialog 
    v-model="dialog" 
    width="70%"
    persistent> -->
		<!-- <template v-slot:activator="{ on, attrs }">
            <v-icon dense class="error--text ml-0 mr-3" v-bind="attrs" v-on="on">mdi-delete</v-icon>
		</template> -->
        <v-tooltip bottom> 
			<template v-slot:activator="{ on, attrs }">
				<v-icon
				v-on="on"
				v-bind="attrs"
				color="alert"
				dense
                @click="dialog=true"
				>mdi-information</v-icon>
			</template>
			<span>Details</span>
		</v-tooltip>

        <v-dialog 
        v-model="dialog" 
        width="50%"
        persistent>
            <v-card>	
                <v-card-title>Shift details</v-card-title>
                <v-divider></v-divider>
                <v-card-text class="black--text py-5 px-10 messageWrapper">
                    <div class="singleRowWrapper" v-for="item in shiftProperties" :key="item.name">
                        <p>{{item.name}}:</p>
                        <p v-if="item.value" class="font-weight-medium">{{item.value}}</p>
                        <p v-else class="font-weight-medium">---</p>
                    </div>
                    <!-- <p class="font-weight-regular black--text mt-6 messageWrapper">Are you sure you want to delete {{title}}?</p> -->
                    <div class="actionButtonsWrapper">
                        <v-btn
                        color="grey"
                        class="mr-0 mt-0"
                        text
                        @click="dialog=false"
                        >
                        CANCEL
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

export default {

	name: 'ShiftDetails',

	data: function(){
        return{
            dialog: false,
            shiftProperties: [
                {
                    name: "Name",
                    value: this.item.shiftName
                },
                {
                    name: "Status",
                    value: this.item.shiftStatus
                },
                {
                    name: "Start date",
                    value: this.item.shiftStartDate
                },
                {
                    name: "End date",
                    value: this.item.shiftEndDate
                },
                {
                    name: "Start time",
                    value: this.item.startTime
                },
                {
                    name: "End time",
                    value: this.item.endTime
                },
                {
                    name: "Settlement",
                    value: this.item.settlement
                },
                {
                    name: "Entering rounding type",
                    value: this.item.enteringRoundingType
                },
                {
                    name: "Entering rounding",
                    value: this.item.enteringRounding+" minutes"
                },
                {
                    name: "Leaving rounding type",
                    value: this.item.leavingRoundingType
                },
                {
                    name: "Leaving rounding",
                    value: this.item.leavingRounding+" minutes"
                },
            ]
        }
	},
    props: {
        item: Object,
    },
    mounted(){
    },
    methods: {
    }
	};
</script>

<style>
.actionButtonsWrapper{
	display: flex;
	justify-content: flex-end;
}

.v-btn{
	width: 80px;
}

.messageWrapper{
    font-size: 1em;
}

.singleRowWrapper{
    display: grid;
    grid-template-columns: repeat(2, 1fr)
}


</style>
